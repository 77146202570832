import React from 'react'
import { graphql, Link } from 'gatsby'

import Carousel from '../../components/carousel'
import Layout from '../../components/layout'
import SEO from '../../components/seo'


export default ({ data }) => {
  const images = [
    { img: data.i1, caption: 'MSR 22 ga Profile' },
    { img: data.i2, caption: 'MSR Deck Only Cutaway' },
    { img: data.i3, caption: 'Epicore MSR Deck Bearing on 6″ Wall' },
    { img: data.i4, caption: 'Epicore MSR Bearing on 6″ Wall with Reinforcing' },
    { img: data.i5, caption: 'Epicore MSR 22 ga Bearing - From Below' },
    { img: data.i6, caption: 'Epicore MSR 20 ga Bearing - From Below' },
    { img: data.i7, caption: 'Primary Shoring' },
    { img: data.i8, caption: 'Reshoring with Post Shores' },
    { img: data.i9, caption: 'Shoring and Reshoring' },
    { img: data.i10, caption: 'Edge Screed/Pour Stop Angle - Ready For Concrete Sub' },
    { img: data.i11, caption: 'Epicore MSR Deck Ready to Pour' },
    { img: data.i12, caption: 'Slab Beam - Above' },
    { img: data.i13, caption: 'Epicore MSR Slab Beams - Below' },
    { img: data.i14, caption: 'Cantilevered Balconies' },
  ];

  return (
    <Layout>
      <SEO title="System Components: EPICORE MSR Floor System" keywords={[]}/>
      <div className="container gallery-container">
        <h2 className='subtitle subtitle--xl color-primary'>System Components</h2>
        <h1 className='title title--xxxl color-primary'>EPICORE MSR Floor System</h1>
        <div className='carousel-container carousel-container--lg'>
          <Carousel
            content={images}
            carWidth={846}
            carHeight={476}
          />
        </div>
        <div>
          <p>Expanding upon the success and knowledge gained in over 20 years and several
            hundred million square feet of the original version Epicore deck, EPIC Metals
            and Infinity Structures introduced the EPICORE MSR Composite Floor System in 2001
            (MSR=Multi-Story Residential).  EPICORE MSR is a dramatic improvement when combined
            with the load-bearing metal stud wall panels to create the Infinity System.  EPICORE
            MSR is a 2” deep, high performance, long span, composite metal deck that acts as both
            the permanent form and the positive reinforcing.  Epicore MSR has triangular dovetail
            shaped ribs at 8” on center that key up into the concrete for exceptional strength
            and span capability.  The bottom flutes are completely closed, and every other rib
            has embossed locking lugs that enhance the interlocking bond between the concrete and
            the EPICORE MSR deck. These innovative design features combined with the flat bottom
            profile of EPICORE MSR make it ideally suited for load-bearing metal stud wall panel
            applications because it inherently distributes loads uniformly over the metal studs
            (eliminating the need for expensive load-distribution devices or alignment of joists
            and studs).  The Epicore MSR System can clear span up to 29 feet.  The field poured
            slab is between a 4” to 8” slab thickness utilizing 4,000 psi regular weight concrete.
            (3,000 psi or lightweight may be used is desired; Consult Infinity for details).</p>

          <p>EPICORE MSR has its own exclusive U.L. fire rating (D-938). The
            “Bare” Epicore MSR slab is automatically UL fire rated, so you do
            not need an expensive fire rated ceiling or spray-on protection.</p>

          <p>With EPICORE MSR, you may select one of many different ceiling treatments from
            leaving it exposed galvanized, or painting the underside, or traditional drywall.</p>

          <p><Link to='/contact/' className='link'>Please contact Infinity
            Structures</Link> for further information including spans, loading,
            specifications, typical details and Engineering assistance. </p>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
    query {
        i1: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/01-41621.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
        i2: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/02-41612.png" }) {
            ...GalleryCarouselTechnical1_77ImageFragment },
        i3: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/03-MSRDk946_(42928).png" }) {
            ...GalleryCarouselImageFragment },
        i4: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/04-MSRDk954_(42936).png" }) {
            ...GalleryCarouselImageFragment },
        i5: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/05-MSRDk850_(33938).png" }) {
            ...GalleryCarouselImageFragment },
        i6: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/06-MSRDk943_(42925).png" }) {
            ...GalleryCarouselImageFragment },
        i7: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/07-Shong050_(40260).png" }) {
            ...GalleryCarouselImageFragment },
        i8: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/08-Shong140_(33939).png" }) {
            ...GalleryCarouselImageFragment },
        i9: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/09-Shong100_(33903).png" }) {
            ...GalleryCarouselImageFragment },
        i10: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/10-MSRDK160_(34277).png" }) {
            ...GalleryCarouselImageFragment },
        i11: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/11-MSRDK240_(34330).png" }) {
            ...GalleryCarouselImageFragment },
        i12: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/12-SlaBe280_(34308).png" }) {
            ...GalleryCarouselImageFragment },
        i13: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/13-SlaBe110_(34258).png" }) {
            ...GalleryCarouselImageFragment },
        i14: file(relativePath: { eq: "system-components/epicore-msr-floor-system/gallery/14-Balco080_(41315).png" }) {
            ...GalleryCarouselImageFragment },
    }
`
